import queryString from "query-string";
import { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { doSignInWithCustomToken, doSignOut } from "./../firebase/firebase";
import { useContext } from "react";
import { FirebaseContext } from "./../firebase";

function SignInViaToken() {
  const [loading, setLoading] = useState(true);
  const { currentUser } = useContext(FirebaseContext);
  let data = queryString.parseUrl(window.location.href, {
    parseNumbers: true,
    parseBooleans: false,
    arrayFormat: "comma",
  }).query;

  useEffect(() => {
    if (data?.token) {
      if (currentUser?.id) {
        doSignOut()
          .then(() => {
            return doSignInWithCustomToken(data.tenantId, data.token);
          })
          .then(() => {
            setLoading(false);
          });
      } else {
        doSignInWithCustomToken(data.tenantId, data.token).then(() => {
          setLoading(false);
        });
      }
    }
  }, [data, currentUser]);

  if (loading)
    return (
      <div className="flex items-center justify-center w-screen min-h-screen text-2xl font-semibold">
        <div className="w-6 h-6 spinner-grow" />
        <div className="ml-3">Loading</div>
      </div>
    );

  return <Navigate to="/" />;
}

export default SignInViaToken;

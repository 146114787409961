import TawkMessengerReact from "@tawk.to/tawk-messenger-react";
import { useContext } from "react";
import { useEffect } from "react";
import { useRef } from "react";
import { FirebaseContext } from "src/firebase";

function ExpertConnect() {
  const tawkMessengerRef = useRef();
  const { currentUser } = useContext(FirebaseContext);
  const onLoad = () => {
    if (tawkMessengerRef.current) {
      tawkMessengerRef.current.visitor({
        name: currentUser.fullName,
        email: currentUser.email,
      });
    }
  };
  const onChatMinimized = () => {
    if (tawkMessengerRef?.current) {
      tawkMessengerRef.current.hideWidget();
    }
  };
  useEffect(() => {
    return () => {
      if (tawkMessengerRef?.current) {
        tawkMessengerRef.current.hideWidget();
      }
    };
  }, [tawkMessengerRef.current]);

  return (
    <div className="flex justify-center p-5">
      <div>
        <button
          className="px-5 py-2 text-white bg-indigo-600 rounded-md"
          onClick={() => {
            if (tawkMessengerRef.current) {
              tawkMessengerRef.current.showWidget();
              tawkMessengerRef.current.maximize();
            }
          }}
        >
          Click here to connect to the expert
        </button>
      </div>
      <TawkMessengerReact
        propertyId="644b039a4247f20fefee311d"
        widgetId="1gv2gc5pa"
        onLoad={onLoad}
        onChatMinimized={onChatMinimized}
        ref={tawkMessengerRef}
      />
    </div>
  );
}

export default ExpertConnect;

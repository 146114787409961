import React from "react";
import { useNavigate } from "react-router-dom";

function NotFound() {
  const navigate = useNavigate();
  return (
    <div className="bg-blue flex flex-col justify-center items-center h-screen w-full">
      <div className="h-full w-full flex flex-col items-center justify-center">
        <div className="w-full flex justify-center">
          <img
            src={"/assets/image/duck.png"}
            className="w-64 h-64 "
            alt="404 Not found"
          />
        </div>
        <div className="flex flex-col justify-center my-16">
          <div className="text-white text-5xl text-center font-semibold">
            Oops something’s wrong
          </div>
          <div className="text-white text-center">
            We’re usually a treasure chest of knowledge, but we couldnt find
            what you’re looking for.
          </div>
        </div>
        <div className="flex justify-center">
          <button
            className="w-64 text-white whitespace-no-wrap bg-black rounded-md py-2"
            onClick={() => navigate("/home", { replace: true })}
          >
            Take me home
          </button>
        </div>
      </div>
    </div>
  );
}

export default NotFound;

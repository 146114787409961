import React from "react";
import SyntaxHighlighter from "react-syntax-highlighter";
import { docco } from "react-syntax-highlighter/dist/esm/styles/hljs";
import yaml from "js-yaml";
import { generateEmbedURL } from "src/utilities/generateEmbedURL";

function MarkdownCodeHighlighter({
  node,
  inline,
  className,
  children,
  ...props
}) {
  const match = /language-(\w+)/.exec(className || "");
  const value = String(children).replace(/\n$/, "");
  if (!value) {
    return null;
  }

  if (inline || !match) {
    return (
      <code className={className} {...props}>
        {children}
      </code>
    );
  }
  const language = match[1];

  try {
    switch (language) {
      case "audio":
        const audio = yaml.load(value);
        return (
          <div className="my-2">
            <audio controls controlsList="nodownload">
              <source src={audio?.src?.trim()} />
              Your browser does not support the audio tag.
            </audio>
          </div>
        );
      case "youtube":
        const youtube = yaml.load(value);
        return (
          <div className="flex justify-center">
            <iframe
              title={youtube}
              src={generateEmbedURL({
                url: youtube?.src?.trim(),
                type: "youtube",
              })}
              className="w-full h-48 py-5 rounded-lg sm:h-80 md:h-110 lg:h-128"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen="true"
            />
          </div>
        );
      case "vimeo":
        const vimeo = yaml.load(value);
        return (
          <div className="flex justify-center">
            <iframe
              title={vimeo}
              src={generateEmbedURL({
                url: vimeo?.src?.trim(),
                type: "vimeo",
              })}
              className="w-full h-48 sm:h-80 md:h-96 lg:h-110 xl:h-128"
              frameBorder="0"
              allow=" fullscreen; picture-in-picture"
              allowFullScreen=""
            />
          </div>
        );

      default:
        return (
          <SyntaxHighlighter language={language} style={docco}>
            {value}
          </SyntaxHighlighter>
        );
    }
  } catch (error) {
    return <div>Invalid YAML code in markdown...</div>;
  }
}

export default MarkdownCodeHighlighter;

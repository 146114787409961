import React from "react";
import { FaUser} from 'react-icons/fa';

function UserAvatar({ profile_picture, fullName, className }) {
  return profile_picture ? (
    <img
      className={`object-cover rounded-full ${className ?? "h-10 w-10"}`}
      src={profile_picture}
      alt="Avatar"
    />
    ) : (
      
  
    
    <div
      className={`rounded-full flex   flex-shrink-0 items-center justify-center font-bold ${
        className ?? "h-10 w-10 text-xl bg-black text-white"
      }`}
    >    <img
    className={`object-cover  ${className ?? "h-10 w-10 flex-none rounded-full"}`}
    src="https://static.vecteezy.com/system/resources/previews/000/573/503/original/vector-sign-of-user-icon.jpg"
    alt="Avatar"
  />  

  <div className="relative mt-2 w-0.5 grow rounded-full bg-neutral-800" ></div>
      {fullName
        ?.split(" ")
        ?.map((el) => (el[0] ? el[0].toUpperCase() : ""))
        ?.join("") ?? ""}
    </div>
  );
}

export default UserAvatar;

import format from "date-fns/format";
import React from "react";
import Skeleton from "react-loading-skeleton";
import { useNavigate } from "react-router-dom";

function CourseCard({
  city,
  thumbnail,
  timestamp,
  credits,
  organiser,
  tags,
  title,
  course_id,
  is_online,
  loading = false,
}) {
  const navigate = useNavigate();
  return (
    <div className="flex w-72 sm:flex-shrink-0 flex-col rounded-md shadow-r1 hover:shadow-r2 hover:-translate-y-3 transform transition-all duration-300">
      {loading ? (
        <div className="h-40">
          <Skeleton height={`100%`} />
        </div>
      ) : (
        <div
          className="flex  h-40 w-full rounded-t-md object-cover flex-col bg-no-repeat bg-cover cursor-pointer"
          style={{ backgroundImage: `url(${thumbnail})` }}
          onClick={() => navigate(`/home/course/${course_id}`)}
        >
          <div className="bg-black-111 h-8 py-4 w-full flex justify-center rounded-t-md">
            <span className="font-medium flex items-center text-white text-sm">
              {format(new Date(timestamp), " MMMM do, yyyy")}
            </span>
          </div>
          {/* <div className="lg:ml-2 ml-2 pb-5 h-24 grid-flow-col grid grid-cols-3 grid-rows-3 mt-1">
            {tags
              ? tags.map((tag) => (
                  <div className=" lg:h-6 m-1 text-white flex items-center">
                    <span className="rounded bg-gray-900 font-medium z-0 text-xs w-20 py-1 text-center capitalize">
                      {tag}
                    </span>
                  </div>
                ))
              : null}
          </div> */}
        </div>
      )}

      <div className="pb-4 w-full bg-white flex text-black flex-col rounded-b-md mt-px h-32 justify-between px-2">
        <div className="mx-3 mt-3 flex">
          {loading ? (
            <div className="w-24">
              <Skeleton />
            </div>
          ) : (
            <div className="text-gray-600 text-xs flex">
              {is_online ? <span>Online</span> : city}
            </div>
          )}
        </div>
        {loading ? (
          <div className="mx-3 w-2/3">
            <Skeleton />
          </div>
        ) : (
          <div
            className=" mx-3 font-medium text-base leading-tight my-3 cursor-pointer "
            onClick={() => navigate(`/home/course/${course_id}`)}
          >
            {title}
          </div>
        )}
        <div className=" mx-3 flex items-center justify-between">
          <div className="text-gray-600 text-sm">
            {loading ? (
              <div className="w-16">
                <Skeleton />
              </div>
            ) : (
              organiser
            )}
          </div>
          {/* <div>
            {loading ? (
              <div className="w-24">
                <Skeleton />
              </div>
            ) : (
              <button
                className="text-xs focus:outline-none border-black border border-gray-600 text-gray-600  text-center items-center w-24 rounded-md font-medium text-sm hover:text-white hover:bg-blue"
                onClick={() => navigate(`/home/course/${course_id}`)}
              >
                Details
              </button>
            )}
          </div> */}
        </div>
      </div>
    </div>
  );
}

export default CourseCard;

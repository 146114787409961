import gql from "graphql-tag";

export const GET_USER_DATA = gql`
  query GetAllUserPlatformParticipationsForDashboard(
    $user_id: uuid!
    $is_student: Boolean!
  ) {
    get_user_test_attempts_summary(args: { userid: $user_id })
      @include(if: $is_student) {
      data
    }
    user_badges(order_by: { created_at: desc }, limit: 9) {
      id
      badge {
        id
        name
        description
        icon
      }
    }
    users(where: { id: { _eq: $user_id } }) {
      id
      fullName
      user_metadata {
        id
        user_metadata_key {
          id
          name
        }
        value
      }
      user_external_website_profiles(
        where: { website: { _eq: "Hacker Rank" } }
      ) {
        id
        website
        username
        verified
      }
      leetcode: user_external_website_profiles(
        where: { website: { _eq: "LeetCode" } }
      ) {
        id
        website
        username
        verified
      }
      has_achievements
      has_certifications
      has_competitions
      has_education
      has_patents
      has_projects
      has_publications
      has_work_experience
      has_workshops
    }
  }
`;

export const GET_SUB_TOPIC_WISE_OVERVIEW = gql`
  query GetTestAttemptSubTopicWiseOverview($userId: uuid!) {
    test_attempt_sub_topic_wise_overview(
      where: {
        test_attempt: { test_participant: { user_id: { _eq: $userId } } }
      }
    ) {
      marks_gained
      marks_lost
      marks_obtained
      marks_skipped
      max_attempted_marks
      max_marks
      percentage
      correct_count
      incorrect_count
      partial_correct_count
      questions_attempted_count
      questions_count
      skipped_count
      time_taken
      attempt_id
      id
      sub_topic_id
      sub_topic {
        id
        name
        topic {
          id
          name
          subject {
            id
            name
          }
        }
      }
    }
  }
`;

export const GET_USER_TEST_DATA = gql`
  query GetUserEnrolledTests($user_id: uuid!) {
    tests(
      where: {
        test_participants: { user_id: { _eq: $user_id } }
        user_visibility: { _eq: true }
      }
      order_by: { created_at: desc }
    ) {
      test_participants(where: { user_id: { _eq: $user_id } }) {
        id
        start_at
        ends_at
      }
      created_by
      is_section_timer_enabled
      id
      is_private
      title
      duration
      test_sections {
        id
        duration
        test_section_questions_aggregate {
          aggregate {
            count
            sum {
              max_marks
            }
          }
        }
      }
    }
  }
`;

export const GET_USER_EVENT_DATA = gql`
  query GetUserEnrolledEvents($user_id: uuid!) {
    events(
      where: { event_participants: { user_id: { _eq: $user_id } } }
      order_by: { start_at: desc }
    ) {
      id
      active
      application_close_at
      application_open_at
      cover_image
      ends_at
      learning_credits
      is_online
      logo
      name
      start_at
      status
      type
      venue
      creator_name
    }
  }
`;

export const GET_DASHBOARD_LEARNING_PATH = gql`
  query GetUserLearningPaths($user_id: uuid!) {
    learning_path(order_by: { created_at: desc }) {
      id
      title
      description
      learning_path_participants(where: { user_id: { _eq: $user_id } }) {
        id
      }
    }
  }
`;

export const GET_USER_COURSE_DATA = gql`
  query GetUserEnrolledCourses($user_id: uuid!) {
    course_bb_participants(
      where: {
        user_id: { _eq: $user_id }
        courses_bb: { user_visibility: { _eq: true } }
      }
      order_by: { created_at: desc }
    ) {
      id
      start_at
      ends_at
      courses_bb {
        id
        tagline
        active
        application_close_at
        application_open_at
        cover_image
        ends_at
        is_online
        learning_credits
        logo
        name
        start_at
        status
        has_lms
        venue
        creator_name
        course_bb_live_classes(
          where: {
            live_session: {
              ends_at: { _gt: "now()" }
              start_at: { _lte: "now()" }
            }
            is_active: { _eq: true }
          }
        ) {
          id
          message
          live_session {
            link
            isRecurring
            isActive
            excluded_dates
            ends_at
            end_time
            description
            title
            type
            start_time
            start_at
          }
        }
      }
    }
  }
`;

export const GET_USER_HACKERRANK_DATA = gql`
  query GetUserEnrolledTestChallenges($user_id: uuid!) {
    test_challenges(
      where: { test_challenge_participants: { user_id: { _eq: $user_id } } }
      order_by: { start_at: desc }
    ) {
      id
      is_active
      is_private
      link
      title
      start_at
      ends_at
    }
  }
`;

export const GET_USER_CHALLENGE_DATA = gql`
  query GetUserEnrolledChallenges($user_id: uuid!) {
    challenge_participants(
      where: { user_id: { _eq: $user_id } }
      order_by: { created_at: desc }
    ) {
      id
      challenge {
        active
        id
        ends_at
        is_online
        name
        creator_logo
        status
        start_at
        id
        domain_list {
          id
          name
        }
      }
    }
  }
`;

export const GET_USER_LIVE_SESSION_DATA = gql`
  query GetUserLiveSessionAndSchedule($user_id: uuid!) {
    live_session(
      where: {
        live_session_participants: { user_id: { _eq: $user_id } }
        start_at: { _lte: "now()" }
        ends_at: { _gte: "now()" }
      }
      order_by: { start_at: desc }
    ) {
      id
      link
      start_at
      ends_at
      start_time
      end_time
      attendence_code
      enable_candidates_feedback
      enable_candidates_feedback_config
      can_candidates_mark_attendence
      title
      type
      venue
      live_session_participants(
        where: { user_id: { _eq: $user_id } }
        order_by: { user: { email: asc } }
      ) {
        user {
          id
          fullName
          email
          profile_picture
          live_session_platform_attendances(where: { date: { _eq: "now()" } }) {
          live_session_id
            date
            status
            candidate_feedback
          }
        }
      }
    }
    schedule(
      where: {
        schedule_group: {
          schedule_participants: { user_id: { _eq: $user_id } }
        }
        starts_at: { _lte: "now()" }
        ends_at: { _gte: "now()" }
      }
      order_by: { starts_at: desc }
    ) {
      id

      title
    }
  }
`;

export const GET_LIVE_SESSION = gql`
  query GetLiveSession($where: live_session_bool_exp!) {
    live_session(where: $where) {
      id
      title
      attendence_code
    }
  }
`;

export const MARK_LIVE_SESSION_ATTENDANCE = gql`
  mutation MarkAttendanceForLiveSession($id: uuid!, $status: String!) {
    insert_live_session_platform_attendance_one(
      object: { live_session_id: $id, status: $status }
      on_conflict: {
        constraint: live_session_platform_attendan_live_session_id_user_id_date_key
        update_columns: [status, updated_at]
      }
    ) {
      id
      live_session_id
      user_id
      status
      updated_at
    }
  }
`;

export const INSERT_FEEDBACK = gql`
  mutation InsertFeedback($id: uuid!, $feedback: String!) {
    insert_live_session_platform_attendance_one(
      object: { live_session_id: $id, candidate_feedback: $feedback }
      on_conflict: {
        constraint: live_session_platform_attendan_live_session_id_user_id_date_key
        update_columns: [candidate_feedback]
      }
    ) {
      id
      live_session_id
      user_id
      status
      updated_at
      candidate_feedback
    }
  }
`;

export const GET_USER_ASSESSMENT_SUMMARY_FOR_DASHBOARD = gql`
  query GetUserAssessmentSummaryForDashboard($id: uuid!) {
    assigned_test: test_participants_aggregate(
      where: { user_id: { _eq: $id } }
    ) {
      aggregate {
        count
      }
    }
    completed_test: test_participants_aggregate(
      where: {
        user_id: { _eq: $id }
        test_attempts: { is_completed: { _eq: true } }
      }
    ) {
      aggregate {
        count
      }
    }
    test_question_submissions_aggregate(
      where: { test_attempt: { test_participant: { user_id: { _eq: $id } } } }
    ) {
      aggregate {
        count
        sum {
          time_taken
        }
      }
    }
  }
`;

export const GET_JOB_POSTING_DASHBOARD = gql`
  query GetJobPostings(
    $where: job_postings_bool_exp! # $limit: Int! = 10 # $offset: Int! = 0
    $id: uuid!
  ) {
    job_postings(
      where: $where
      # limit: $limit
      # offset: $offset
      order_by: { created_at: desc }
    ) {
      id
      openings
      stipend_to
      stipend_from
      title
      duration
      type
      created_at
      start_at
      isActive
      application_close_date
      skills
      contract_type
      college {
        id
        name
        tagline
        logo
      }
      job_applicants(where: { user_id: { _eq: $id } }) {
        id
        status
      }
      job_applicants_aggregate {
        aggregate {
          count
        }
      }
    }
    job_postings_aggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
`;

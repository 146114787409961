import { format, isFuture, isPast } from "date-fns";
import React, { useMemo } from "react";
import Skeleton from "react-loading-skeleton";
import { useNavigate } from "react-router-dom";
import { twMerge } from "tailwind-merge";

function TestCard({
  title,
  start_at,
  ends_at,
  is_cut_off,
  test_id,
  loading = false,
  hasAttempts,
  hasCompleted,
}) {
  const navigate = useNavigate();
  const active = useMemo(() => {
    if (
      start_at &&
      ends_at &&
      isPast(new Date(start_at)) &&
      isFuture(new Date(ends_at))
    ) {
      return true;
    }
    return false;
  }, [start_at, ends_at]);
  // const active = true;
  return (
    <div
      style={{ minWidth: "20rem" }}
      className={twMerge(
        "pb-3 flex bg-gray-E5 hover:bg-white hover:text-black w-96 flex-grow xl:flex-grow-0 sm:flex-shrink-0 flex-col rounded-md shadow-r1 hover:shadow-r2 hover:-translate-y-3 transform transition-all duration-300 cursor-pointer pointer-events-auto",
        active
          ? "bg-blue text-white"
          : hasAttempts
          ? "bg-green-200"
          : "bg-black-D2 pt-3"
      )}
      onClick={() => {
        if (!loading) {
          navigate(`/home/test/${test_id}`);
        }
      }}
    >
      {active & !hasCompleted ? (
        <div className="flex justify-end w-full pr-3">
          <div className="px-3 text-sm text-white transform -translate-y-2 bg-orange-600 rounded-md">
            LIVE
          </div>
        </div>
      ) : null}
      {hasCompleted ? (
        <div className="flex justify-end w-full pr-3">
          <div className="px-3 text-sm text-white transform -translate-y-2 bg-orange-600 rounded-md">
            Completed
          </div>
        </div>
      ) : null}
      <div className="flex flex-col justify-between w-full px-2 mt-px rounded-b-md">
        {loading ? (
          <div className="w-2/3 mx-3">
            <Skeleton count={2} />
          </div>
        ) : (
          <div className="mx-3 my-3 text-base font-medium leading-tight cursor-pointer ">
            {title}
          </div>
        )}
      </div>

      <div>
        {loading ? (
          <Skeleton count={2} />
        ) : (
          <div className="flex flex-col gap-2 px-5 pb-4">
            <span className="flex items-center text-sm font-medium">
              {`Starts at - ${format(new Date(start_at), "PPPpp")}`}
            </span>
            <span className="flex items-center text-sm font-medium">
              {`Ends at - ${format(new Date(ends_at), "PPPpp")}`}
            </span>
          </div>
        )}
      </div>
    </div>
  );
}

export default TestCard;

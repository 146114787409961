import React, { useState, useContext } from "react";
import { FaDiceD20, FaShapes } from "react-icons/fa";
import { doSignOut, FirebaseContext } from "src/firebase";
import { useNavigate, Link } from "react-router-dom";

//TODO USE CONFIG OR UNIVERSITY TABLE
function ProfileMenu(props) {
  const navigate = useNavigate();
  const { currentUser } = useContext(FirebaseContext);
  console.log("currentUser: ", currentUser);
  const [open, setOpen] = useState(false);
  return (
    <div className="relative block items-center w-10 ml-1">
      <div className="flex flex-shrink-0">
        <button className="flex flex-shrink-0" onClick={() => setOpen(!open)}>
          {currentUser.profile_picture ? (
            <img
              className="object-cover h-10 w-10 rounded-full flex-shrink-0"
              src={currentUser.profile_picture}
              alt="Avatar"
            />
          ) : (
            <div className="rounded-full h-8 w-8 text-lg bg-blue text-white flex items-center justify-center font-bold">
              {[currentUser.fullName]
                .map((el) => el?.[0]?.toUpperCase() ?? "")
                .join("")}
            </div>
          )}
        </button>
      </div>
      <div className={`ml-4 ${open ? "block" : "hidden"}`}>
        <button
          className={`fixed inset-0 w-full h-full cursor-default ${
            open ? "block" : "hidden"
          }`}
          onClick={() => setOpen(false)}
        ></button>
        <div className="absolute text-black right-0 mr-4 mt-3 shadow-md md:mt-8 rounded-md z-50">
          <div className={`bg-white rounded-md pb-4`}>
            <div className="w-full flex h-40 items-center justify-between py-4">
              <div className="flex mx-4 w-full items-center">
                <div>
                  <button
                    className="mx-3 w-24 h-24"
                    onClick={() => {
                      setOpen(false);
                      navigate(`/home/profile`);
                    }}
                  >
                    {currentUser.profile_picture ? (
                      <img
                        className="object-cover h-20 w-20 rounded-full"
                        src={currentUser.profile_picture}
                        alt="Avatar"
                      />
                    ) : (
                      <div className="rounded-full h-24 w-24 text-3xl bg-blue text-white flex items-center justify-center font-bold">
                        {[currentUser.fullName]
                          .map((el) => el?.[0]?.toUpperCase() ?? "")
                          .join("")}
                      </div>
                    )}
                  </button>
                </div>
                <div className="flex mt-2 flex-col text-sm font-semibold text-left">
                  <span>{`${currentUser.fullName ?? ""}`}</span>
                  <span className="text-xs text-gray-600 font-normal">
                    {currentUser.email}
                  </span>
                  <div>
                    <button
                      className="text-left text-xs font-semibold"
                      onClick={() => {
                        setOpen(false);
                        navigate(`/home/profile`);
                      }}
                    >
                      {"View Profile"}
                    </button>
                  </div>
                  <div className="flex mt-3">
                    <div className="flex items-center">
                      <FaDiceD20 className="h-6 w-6 mr-3 fill-current text-blue font-normal" />
                      <span>{0}</span>
                    </div>
                    <div className="flex items-center">
                      <FaShapes className="h-5 w-5 mx-3 fill-current text-blue" />
                      <span
                        className={`${
                          0.0 > 5.5 ? "text-green-700" : "text-red-700"
                        } font-normal`}
                      >
                        {"0.0"}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* <hr className="mb-2"></hr> */}
            <div className="flex flex-col">
              <Link
                className="p-2 text-left text-sm px-8"
                to="/home/edit"
                onClick={() => {
                  setOpen(false);
                }}
              >
                Edit Profile
              </Link>
              {/* {currentUser.user_role.role.name === "faculty" ||
              currentUser.user_role.role.name === "manager" ? (
                <Link
                  className="p-2 text-left text-sm px-8"
                  to="/creator"
                  onClick={() => {
                    setOpen(false);
                  }}
                >
                  Creator Dashboard
                </Link>
              ) : null} */}
              {currentUser.university.config.communities ? (
                <Link
                  to="/home/communities"
                  className="p-2 text-left text-sm px-8"
                  onClick={() => setOpen(false)}
                >
                  <span className="">Communities</span>
                </Link>
              ) : null}
              {/* <button className="p-2 text-left text-sm px-8">
                My Activity
              </button> */}

              {/* <Link className="p-2 text-left text-sm px-8" to="/creator">
                Sharer Dashboard
              </Link> */}
              {/* <button className="p-2 text-left text-sm px-8">
                Account Settings
              </button> */}
            </div>
            <hr className="my-2"></hr>
            <div className="flex flex-col py-2">
              {currentUser.university.support_email ? (
                <a
                  href={`mailto:${currentUser.university.support_email}`}
                  className="p-2 text-left text-sm px-8"
                  onClick={() => {
                    setOpen(false);
                  }}
                >
                  {`Help (Mail to ${currentUser.university.support_email})`}
                </a>
              ) : null}
              <button
                className="p-2 text-left text-sm px-8"
                onClick={doSignOut}
              >
                Logout
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProfileMenu;

import gql from "graphql-tag";
export const GET_PROFILE_DATA = gql`
  query GetInitialUserProfileData($email: String!) {
    users(where: { email: { _eq: $email } }) {
      fullName
      id
      onboarding_complete

      user_role {
        id
        role {
          id
          name
        }
      }
      is_trainer
      onboarding_step
      profile_picture
      university_id
      university {
        id
        name
        description
        config
        support_email
        icon
        private_config
        isJobPortal
      }
    }
  }
`;

import gql from "graphql-tag";

export const GET_ALL_LIVE_SESSIONS = gql`
  query GetAllLiveSessions {
    live_session(order_by: { start_at: desc }) {
      id
      title
      link
      start_at
      ends_at
      start_time
      end_time
      type
      isRecurring
      attendence_code
      enable_candidates_feedback
      enable_candidates_feedback_config
      can_candidates_mark_attendence
      live_session_participants(
        order_by: { user: { email: asc } }
      ) {
        user {
          id
          fullName
          email
          profile_picture
          live_session_platform_attendances(where: { date: { _eq: "now()" } }) {
          live_session_id
            date
            status
            candidate_feedback
          }
        }
      }
    }
  }
`;

export const GET_LIVE_SESSION = gql`
  query GetLiveSession($where: live_session_bool_exp!) {
    live_session(where: $where) {
      id
      title
      attendence_code
    }
  }
`;

export const MARK_LIVE_SESSION_ATTENDANCE = gql`
  mutation MarkAttendanceForLiveSession($id: uuid!, $status: String!) {
    insert_live_session_platform_attendance_one(
      object: { live_session_id: $id, status: $status }
      on_conflict: {
        constraint: live_session_platform_attendan_live_session_id_user_id_date_key
        update_columns: [status, updated_at]
      }
    ) {
      id
      live_session_id
      user_id
      status
      updated_at
    }
  }
`;

export const INSERT_FEEDBACK = gql`
  mutation InsertFeedback($id: uuid!, $feedback: String!) {
    insert_live_session_platform_attendance_one(
      object: { live_session_id: $id, candidate_feedback: $feedback }
      on_conflict: {
        constraint: live_session_platform_attendan_live_session_id_user_id_date_key
        update_columns: [candidate_feedback]
      }
    ) {
      id
      live_session_id
      user_id
      status
      updated_at
      candidate_feedback
    }
  }
`;
import React from "react";
import { useMemo } from "react";

function CircularProgress({
  value = "",
  percent = 0,
  className = "",
  size = 150,
  stroke = 12,
  strokeColor = "#5eaefd",
  title = null,
  //   offset = 4,
  //   handleSize = 22,
  randomize = false,
}) {
  const randomPercent = useMemo(() => {
    if (randomize) {
      return (Math.random() * 100).toFixed(2);
    }
    return 0;
  }, [randomize]);
  return (
    <div
      className={`circular-progress ${className} flex-shrink-0`}
      style={{ width: size, height: size }}
    >
      <div className="movable circular-progress-track">
        <svg
          className="radial-filler"
          viewBox="0 0 100 100"
          xmlns="http://www.w3.org/2000/svg"
        >
          <defs>
            <linearGradient
              id="circular-progress-gradient"
              x1="0%"
              y1="0%"
              x2="100%"
              y2="0%"
            >
              <stop offset="0%" stop-color="#00bc9b"></stop>
              <stop offset="100%" stop-color="#5eaefd"></stop>
            </linearGradient>
            <filter id="inset-shadow">
              <feFlood flood-color="rgba(0,0,0,0.3)"></feFlood>
              <feComposite operator="out" in2="SourceGraphic"></feComposite>
              <feGaussianBlur stdDeviation="0.5"></feGaussianBlur>
              <feComposite operator="atop" in2="SourceGraphic"></feComposite>
            </filter>
          </defs>
          <circle
            cx="50"
            cy="50"
            r="44"
            strokeWidth={`${stroke}`}
            strokeDasharray="230.3834612632515 276.46015351590177"
            strokeDashoffset="0"
            strokeLinecap="round"
            filter="url(#inset-shadow)"
            stroke="#f0f2f5"
            style={{ transform: "rotate(120deg)" }}
          ></circle>
          <circle
            cx="50"
            cy="50"
            r="44"
            strokeWidth={`${stroke}`}
            className="transition-all duration-300 ease-in-out"
            strokeDasharray={`${
              ((randomize ? randomPercent : percent) / 100) * 230.3834612632515
            } 276.46015351590177`}
            strokeDashoffset="0"
            strokeLinecap="round"
            // stroke="url(#circular-progress-gradient)"
            stroke={strokeColor}
            style={{ transform: "rotate(120deg)" }}
          ></circle>
        </svg>
        {/* <div
          className="circular-progress-handle"
          style={{
            transform: `rotate(${210 + 3 * percent}deg)`,
            width: `${handleSize}px`,
            height: `${handleSize}px`,
            borderRadius: `${handleSize}px`,
            transformOrigin: `${Math.ceil(handleSize / 2)}px ${
              size / 2 - offset
            }px`,
            left: `${Math.floor((size - handleSize) / 2)}px`,
            top: `${offset}px`,
          }}
        ></div> */}
        <div className="circular-progress-value">
          <div className="text-lg font-medium">{title}</div>{" "}
          {randomize ? `${randomPercent}%` : value}
        </div>
      </div>
    </div>
  );
}

export default CircularProgress;

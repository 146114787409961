import React, { useContext } from "react";
import Modal from "react-modal";
import { toast } from "react-toastify";
import { doSignInWithGoogle, FirebaseContext } from "src/firebase";
import AuthContextProvider from "./contextProvider";

export const AuthModalContext = React.createContext({});

const AuthModal = ({
  type,
  handleAuthModal,
  open,
  setIsSigningIn,
  isSigningIn,
}) => {
  const { currentUser } = useContext(FirebaseContext);

  console.log(open, type);
  const handleSignInWithGoogle = () => {
    setIsSigningIn(true);
    doSignInWithGoogle()
      .then(async (user) => {
        // const IDResult = await user.getIdTokenResult();
        // console.log("USERIDResult: ", IDResult);
        //loadingBarhastoBeShowninModel
        //enbleLoadingStateinModel
        //DataBase
        // const IDResult = await firebase.auth().currentUser.getIdTokenResult();
        // console.log("USERIDResult: ", IDResult);
        // console.log("USERR", user);
        //Business logic
        // if (newUser) {
        //   insertMutation(insertintoTable);
        //   //succefull
        //makeLoading
        //navigate("")
        // } else {
        //   updateMutation(lastsignintime);
        // }
        // setIsSignInSuccessful(false);
        // setLoading(false);
        // handleAuthModal({ open: false, type: 0 });
      })
      .catch((error) => {
        setIsSigningIn(false);
        // handleAuthModal({ type: 0, open: false });
        toast.error(`Couldn't sign in - ${error.message ? error.message : ""}`);
        console.log(error);
      });
  };

  return (
    <Modal
      isOpen={currentUser ? false : open}
      onRequestClose={() => {
        if (!isSigningIn) handleAuthModal({ open: false, type: 0 });
      }}
      htmlOpenClassName="overflow-hidden"
      bodyOpenClassName="overflow-hidden"
      closeTimeoutMS={300}
      className="inset-y-auto inset-x-auto bg-white p-0 rounded-md focus:outline-none overflow-y-auto max-h-full"
      overlayClassName="transition-all ease-in-out duration-300 flex justify-center items-center bg-opacity-75 bg-black inset-0 fixed p-8"
    >
      {/* <div className="rounded-l-md min-h-full w-110 bg-cover hidden lg:flex ">
        <img
          src={authImage}
          alt="Placeholder"
          className="rounded-l-md h-full w-full object-cover"
        />
      </div> */}
      <div className="flex justify-center px-24 my-16">
        <div className="w-64">
          <div className="flex justify-center">
            <img src={"/assets/image/authImage2.png"} alt="logo" />
          </div>
          <div className="justify-center flex flex-col items-center">
            <div className="text-2xl font-semibold">Hey there!</div>
            <div className="text-xl font-normal">Login to BloomBloom</div>
          </div>
          <div className="mt-8">
            <button
              className="flex items-center justify-center px-5 rounded-md bg-black-111 text-white py-2 mt-12 w-full"
              onClick={handleSignInWithGoogle}
              disabled={isSigningIn}
            >
              <div>
                {isSigningIn ? (
                  <div className="spinner-grow w-6 h-6 mr-3"></div>
                ) : (
                  <img
                    src={"/assets/image/googleLogo.svg"}
                    alt="logo"
                    className="h-4 w-4 mx-3"
                  />
                )}
              </div>
              <div>{isSigningIn ? "Loading..." : "Continue with Google"}</div>
            </button>
            {/* <div className="flex items-center mt-3">
              <hr className="w-full h-px border-none bg-black" />
              <div className="px-3 text-sm">or</div>
              <hr className="w-full h-px border-none bg-black" />
            </div>
            {type === 0 && (
              <SignIn
                goToSignUp={() => handleAuthModal({ open: true, type: 1 })}
              />
            )}
            {type === 1 && (
              <SignUp
                goToSignIn={() => handleAuthModal({ open: true, type: 0 })}
              />
            )} */}
          </div>
        </div>
      </div>
    </Modal>
  );
};
export default AuthModal;

export { AuthContextProvider };

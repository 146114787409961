import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { MdEvent, MdClose } from "react-icons/md";

const CustomDatePickerComponent = ({ value, onClick, clearValue, padded }) => (
  <div
    className={`form-input w-full bg-white focus:shadow-none text-sm border-gray-400 focus:border-blue-r1 flex items-center cursor-pointer ${
      padded ? "h-12 px-8" : "h-10 px-5"
    }`}
    onClick={onClick}
  >
    <div className="w-full flex items-center relative pr-16">
      <div className="text-overflow-dots" title={value}>
        {value}
      </div>
      {value ? (
        <MdClose
          className="absolute w-6 h-6 right-0 mr-8 text-gray-CE"
          onClick={(e) => {
            e.preventDefault();
            clearValue();
          }}
        />
      ) : null}
      <MdEvent className="absolute w-6 h-6 right-0 text-gray-CE" />
    </div>
  </div>
);
const CustomDatePicker = ({
  field: { name, value },
  form: { values, setFieldValue },
  showMonthYearPicker = false,
  showYearPicker = false,
  showYearDropdown = false,
  showTimeSelect = false,
  format,
  padded = false,
  showTimeSelectOnly = false,
  customOnChange,
  customValue,
  ...props
}) => {
  return (
    <DatePicker
      {...props}
      className="w-full"
      wrapperClassName="w-full"
      showMonthYearPicker={showMonthYearPicker}
      showYearPicker={showYearPicker}
      selected={customValue ? customValue : value}
      showYearDropdown={showYearDropdown}
      onChange={(date) => {
        if (customOnChange) {
          customOnChange(date);
        } else {
          setFieldValue(name, date);
        }
      }}
      showTimeSelect={showTimeSelect}
      showTimeSelectOnly={showTimeSelectOnly}
      customInput={
        <CustomDatePickerComponent
          padded={padded}
          clearValue={() => setFieldValue(name, null)}
        />
      }
      dateFormat={
        format ? format : showTimeSelect ? "do MMMM yyyy HH:mm" : "do MMMM yyyy"
      }
    />
  );
};
export default CustomDatePicker;

import { format } from "date-fns";
import React from "react";

function ProductDetailCard(props) {
  return (
    <div className=" inline-block bg-white p-8 shadow-md rounded-md">
      <div className="w-64 flex flex-col">
        <span className="text-xs text-gray-600">
          {"Added "}
          <span className="font-semibold">
            {format(new Date(props.addedTimestamp), "MMMM yyyy")}
          </span>
        </span>
        <span className="text-xl font-semibold tracking-tight mt-3 leading-6">
          {props.title}
        </span>
        <span className="text-xs text-gray-600 mt-3">
          <span className="text-blue">{`${props.duration} days`}</span>
          {" • "}
          <span className="text-blue">{`${props.difficultyLevel} Level`}</span>
          {" • "}
          <span className="text-blue">
            {props.certificate ? "Certificate" : "No Certificate"}
          </span>
        </span>

        <span className="mt-3 text-xs text-gray-600 leading-4 tracking-tight">
          {props.title}
        </span>
      </div>
      <div className="mt-4 flex items-center justify-between ">
        <button className="bg-blue text-center items-center w-32 rounded-md p-2 text-white text-sm">
          Add to cart
        </button>
        <span className="text-sm text-gray-600 font-medium">
          {"View course"}
        </span>
      </div>
      <hr className="w-64 my-6" />
      <div className="flex items-center justify-center">
        <div className="flex items-center mr-5">
          <img
            className="object-cover rounded-full h-12 w-12"
            src={props.author.avatar}
            alt="Avatar"
          />
        </div>
        <div className="flex flex-col">
          <span className="font-semibold text-sm">{props.author.fullName}</span>
          <span className="text-xs text-gray-600">{`${props.author.job}, ${props.author.city}`}</span>
        </div>
      </div>
    </div>
  );
}

export default ProductDetailCard;

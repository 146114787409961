import React from "react";
import { NumberFormatBase } from "react-number-format";
import { MdRemoveShoppingCart } from "react-icons/md";

function Cart(props) {
  return (
    <div className={`relative ml-4 ${props.open ? "block" : "hidden"}`}>
      <button
        className={`fixed inset-0 w-full h-full bg-black opacity-50 cursor-default ${
          props.open ? "block" : "hidden"
        }`}
        onClick={() => props.handleCart(false)}
      ></button>
      <div className="md:absolute fixed  top-0 w-full md:w-128 text-black right-0 mt-3  md:mt-8">
        <div className={`bg-white rounded-md pb-4`}>
          <div className="flex  p-8 py-4 md:py-6 justify-between w-full items-center">
            <span className="text-sm">Your Cart</span>
          </div>
          <hr />
          <div className="flex flex-col my-8">
            {false ? (
              "...".split("").map((el) => (
                <div className="flex items-center justify-center px-8 mb-3">
                  <img
                    className="w-24 h-12 object-cover bg-center bg-cover rounded-md mr-4"
                    src={"/assets/image/placeholder1.png"}
                    alt="img"
                  ></img>
                  <div className="flex flex-col">
                    <span className="text-sm font-semibold leading-4">
                      {
                        "Learn to Color Grade and Edit video with DaVinci Resolve 15"
                      }
                    </span>
                    <div className="flex justify-between items-center mt-1">
                      <span className="text-xs text-gray-600">
                        {"Draplin Andres"}
                      </span>
                      <span className="text-xs font-semibold">
                        <NumberFormatBase
                          value={"123"}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={"$"}
                          decimalScale={2}
                          fixedDecimalScale={true}
                        />
                      </span>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div className="flex flex-col items-center justify-center my-16">
                <div>
                  <MdRemoveShoppingCart className="h-24 w-24" />
                </div>
                <div className="text-lg text-gray-600">No items in cart</div>
              </div>
            )}
          </div>
          <hr />
          <div className="flex p-3 justify-between items-center mt-2 px-8">
            <div>
              <span className="font-semibold">
                <span>Total: </span>
                <NumberFormatBase
                  value={0}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={"$"}
                  decimalScale={2}
                  fixedDecimalScale={true}
                />
              </span>
            </div>
            <div>
              <button className=" py-2 bg-blue text-center items-center  w-24 rounded-md  font-medium text-sm text-white">
                Checkout
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Cart;

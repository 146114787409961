// Remove the apollo-boost import and change to this:
import { InMemoryCache } from "@apollo/client";
import { ApolloClient } from "@apollo/client";
import { ApolloLink } from "@apollo/client";
import { createHttpLink } from "@apollo/client";
import LogRocket from "logrocket";
import React, {
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { ApolloProvider, useApolloClient } from "@apollo/client";
// import OneSignal from "react-onesignal";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import App from "./App";
import { AuthModalContext } from "src/components/authModal";
import firebase, { FirebaseContext } from "src/firebase";
import * as FullStory from "@fullstory/browser";
import { GET_PROFILE_DATA } from "./queries";
import { FUNCTION_BASE_URL } from "src/utilities/constants";
import queryString from "query-string";
// const HASURA_GRAPHQL_ENGINE_HOSTNAME =
//   process.env.REACT_APP_HASURA_GRAPHQL_ENGINE_HOSTNAME;
// const HASURA_GRAPHQL_ENGINE_HOSTNAME = "present-moccasin-77.hasura.app";
// const HASURA_GRAPHQL_ENGINE_HOSTNAME = "exams.meritcurve.com";
// const HASURA_GRAPHQL_ENGINE_HOSTNAME = "exams.hasura.app";
const HASURA_GRAPHQL_ENGINE_HOSTNAME = "hasura.meritcurve.com";

// const HASURA_GRAPHQL_ENGINE_HOSTNAME = "139.59.7.118";

// const wsurl = `wss://${HASURA_GRAPHQL_ENGINE_HOSTNAME}/v1/graphql`;
//TODO Make hasura config
// const httpurl = `https://${process.env.REACT_APP_HASURA_GRAPHQL_URL}/v1/graphql`;
// const httpurl = `http://localhost:8080/v1/graphql`;
const httpurl = `https://${HASURA_GRAPHQL_ENGINE_HOSTNAME}/v1/graphql`;
const wssurl = `wss://${HASURA_GRAPHQL_ENGINE_HOSTNAME}/v1/graphql`;
// toast.configure({})

const AuthToken = forwardRef(
  ({ setPending, setCurrentUser, currentUser: currentUserData }, ref) => {
    const [token, setToken] = useState(null);
    let queryParam = queryString.parseUrl(window.location.href, {
      parseNumbers: true,
      parseBooleans: false,
      arrayFormat: "comma",
    }).query;
    const navigate = useNavigate();
    const {
      handleAuthModal,
      setIsSigningIn,
      // authModalState,
      // isSigningIn,
    } = useContext(AuthModalContext);
    // const {} =

    const client = useApolloClient();

    // console.log(data, loading, error);

    useEffect(() => {
      if (firebase.auth().isSignInWithEmailLink(window.location.href)) {
        var email = window.localStorage.getItem("emailForSignIn");
        if (!email) {
          email = window.prompt("Please provide your email for confirmation");
        }
        firebase
          .auth()
          .signInWithEmailLink(email, window.location.href)
          .then(function (result) {
            window.localStorage.removeItem("emailForSignIn");
            history.replace("/");
          })
          .catch(function (error) {
            toast.error("Something went wrong.");
            console.log("error", error);
          });
      } else {
      }
    }, []);
    useEffect(() => {
      const currentUser = firebase.auth().currentUser;
      if (token && currentUser && !currentUserData) {
        client
          .query({
            query: GET_PROFILE_DATA,
            variables: {
              email: currentUser.email,
            },
          })
          .then(({ data, loading }) => {
            if (data?.users[0]?.university?.config?.ui_disable) {
              throw new Error("Error 503: Internal Server Error");
            }
            if (!data.users[0]) {
              throw new Error("User not found");
            }
            if (
              data.users[0]?.university_id ===
              "cd970206-a73e-4dbe-805d-d1f06f5eadb4"
            ) {
              LogRocket.init("ctlnqo/trecruit-4");
              LogRocket.identify(currentUser.email, {
                name: currentUser.displayName,
                email: currentUser.email,
              });
            }
            FullStory.identify(currentUser.email, {
              displayName: currentUser.displayName,
              email: currentUser.email,
            });
            setCurrentUser({
              ...currentUser,
              ...data.users[0],
            });

            toast.success(`Signed in as ${currentUser.email}`);
            handleAuthModal({ open: false, type: 0 });
            setIsSigningIn(false);
            // }
            setPending(false);
            console.log("false1", false);
            if (!data.users[0].onboarding_complete) {
              navigate(`/welcome`, { replace: true });
            }
            //  else if (
            //   data.users[0].user_role.role.name !== "student" &&
            //   window.location.pathname.startsWith("/home/dashboard")
            // ) {
            //   navigate(`/creator`);
            // }
          })
          .catch((error) => {
            firebase.auth().signOut();
            console.log("Error", error);
            toast.error(error.message ?? `Couldn't sign in`);
            handleAuthModal({ open: false, type: 0 });
            setIsSigningIn(false);
            setPending(false);
            console.log("false2", false);
          });
      }
    }, [token]);
    useEffect(() => {
      const intervalId = setInterval(async () => {
        if (firebase.auth().currentUser) {
          const token = await firebase.auth().currentUser.getIdToken(true);
          setToken(token);
        }
      }, 2700000);
      return () => {
        clearInterval(intervalId);
      }; // eslint-disable-next-line
    }, [token]);

    useEffect(() => {
      const unsubscribe = firebase.auth().onAuthStateChanged(
        async (user) => {
          try {
            if (user) {
              const idTokenResult = await user.getIdTokenResult();
              const hasuraClaim =
                idTokenResult.claims["https://hasura.io/jwt/claims"];
              if (hasuraClaim) {
                const token = await user.getIdToken(true);
                setToken(token);
              } else {
                const token = await user.getIdToken(true);
                const tokenRes = await fetch(
                  `${FUNCTION_BASE_URL}/meritcurve/user/setCustomToken?token=${token}`,
                  {
                    method: "POST",
                    body: JSON.stringify({
                      slug: queryParam.slug,
                    }),
                  }
                );
                if (tokenRes.status !== 200) {
                  throw new Error(await tokenRes.text());
                }
                // const { token: signInToken } = await tokenRes.json();
                // const { user: firebaseUser } = await firebase
                //   .auth()
                //   .signInWithCustomToken(signInToken);
                const newToken = await user.getIdToken(true);
                setToken(newToken);
              }
              setIsSigningIn(false);
              // if (hasuraClaim) {
              //   const token = await user.getIdToken(true);
              //   setToken(token);
              // } else {
              //   const token = await user.getIdToken();
              //   fetch(
              //     `${process.env.REACT_APP_PROCESS_SIGNUP_URL}?token=${token}`,
              //     { method: "GET" }
              //   )
              //     .then(async (response) => {
              //       if (response.status === 200) {
              //         await user.reload();
              //         const token = await user.getIdToken(true);
              //         setToken(token);
              //       } else {
              //         handleAuthModal({ open: false, type: 0 });
              //         setIsSigningIn(false);
              //         firebase.auth().signOut();
              //         const resMessage = await response.text();
              //         toast.error(
              //           `Couldn't sign in. Please get in touch with support team.`
              //         );
              //         console.log("Something went wrong" + resMessage);
              //       }

              //       setPending(false);
              //     })
              //     .catch((error) => {
              //       firebase.auth().signOut();
              //       handleAuthModal({ open: false, type: 0 });
              //       setIsSigningIn(false);
              //       toast.error("Cannot contact server");
              //       console.log(
              //         "Cannot contact server" + JSON.stringify(error)
              //       );
              //       setPending(false);
              //     });
              // }
            } else {
              setPending(false);
              console.log("false4", false);
              setCurrentUser(null);
            }
          } catch (error) {
            toast.error(error.message);
            firebase.auth().signOut();
            console.log("error", error);
          }
        },
        () => {}
      );
      return () => {
        unsubscribe();
      }; // eslint-disable-next-line
    }, []);
    useImperativeHandle(ref, () => ({
      token,
      setToken,
    }));
    return null;
  }
);

const Apollo = () => {
  const {
    handleAuthModal,
    setIsSigningIn,
    // authModalState,
    // isSigningIn,
  } = useContext(AuthModalContext);

  const authRef = useRef();
  const [currentUser, setCurrentUser] = useState(null);

  const [pending, setPending] = useState(true);

  // const wsLink = new WebSocketLink({
  //   uri: wsurl,
  //   options: {
  //     reconnect: true,
  //     connectionParams: {
  //       authorization: token ? `Bearer ${token}` : "",
  //     },
  //   },
  // });

  const httpLink = createHttpLink({ uri: httpurl });
  const middlewareLink = new ApolloLink((operation, forward) => {
    const token = authRef.current?.token;
    operation.setContext({
      headers: {
        authorization: token ? `Bearer ${token}` : null,
      },
    });
    return forward(operation);
  });

  // use with apollo-client
  const link = middlewareLink.concat(httpLink);

  // const httpLink = new HttpLink({
  //   uri: httpurl,
  //   headers: {
  //     authorization: token ? `Bearer ${token}` : "",
  //   },
  // });
  // const link = split(
  //   // split based on operation type
  //   ({ query }) => {
  //     const { kind, operation } = getMainDefinition(query);
  //     return kind === "OperationDefinition" && operation === "subscription";
  //   },
  //   wsLink,
  //   httpLink
  // );

  const client = new ApolloClient({
    // link: httpLink,
    link,
    cache: new InMemoryCache(),
    defaultOptions: {
      watchQuery: { fetchPolicy: "cache-and-network" },
    },
  });

  return (
    <ApolloProvider client={client}>
      <AuthToken
        ref={authRef}
        {...{ currentUser, setPending, setCurrentUser }}
      />
      <FirebaseContext.Provider
        value={{
          firebase,
          currentUser,
          setCurrentUser,
          setToken: authRef.current?.setToken,
          token: authRef.current?.token,
          // getProfileData,
        }}
      >
        <App
          token={authRef.current?.token}
          tokenRef={authRef}
          pending={pending}
          setPending={setPending}
          setCurrentUser={setCurrentUser}
        />
        <ToastContainer
          // bodyClassName="rounded-md"
          // className="rounded-md"
          toastClassName="rounded-md px-5"
        />
      </FirebaseContext.Provider>
    </ApolloProvider>
  );
};
export default Apollo;
